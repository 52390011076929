<template>
  <div>
    <b-card
      title="Settle Invoice"
      class="settle-invoice-card"
    >
      <h5>
        Invoice
        <span class="invoice-number">#{{ paymentInvoiceId }}</span>
      </h5>
    </b-card>
    <b-card
      class="instructions-card"
    >Follow The Instructions Below <strong>or</strong> Enter Your Phone Number
      To Get Payment Prompt.</b-card>
    <b-row>
      <b-col
        lg="5"
        md="6"
        sm="12"
      >
        <b-card>
          <h5>Payment Instructions</h5>
          <ol>
            <li>Go to Mpesa Menu on your phone.</li>
            <li>Select Lipa na Mpesa</li>
            <li>Select Paybill</li>
            <li>Enter Paybill No. <strong>884350</strong>, then press Ok</li>
            <li>
              Enter Account No. <strong>{{ paymentInvoiceId }}</strong>, then press Ok
            </li>
            <li>
              Enter amount Ksh. <strong>{{ new Intl.NumberFormat().format(getInvoiceBalance(invoiceData, getDiscountedAmount(invoiceData))) }}</strong>, then press Ok
            </li>
            <li>Enter Your PIN and press Ok</li>
            <li>Confirm the transaction details</li>
            <li>Wait for the MPESA transaction confirmation message</li>
            <li>
              Press the <strong>Complete</strong> button below to confirm the
              transaction
            </li>
          </ol>
        </b-card>
      </b-col>
      <b-col
        lg="5"
        md="6"
        sm="12"
      >
        <b-card>
          <validation-observer ref="refEnterPhoneNumberForm">
            <ul>
              <li>Enter Your Phone Number and click Send Prompt to get MPESA Prompt</li>
            </ul>
            <b-form-group
              label="Enter Phone Number"
              label-for="enterBusinessName"
            >
              <validation-provider
                #default="{ errors }"
                name="Phone Number"
                rules="required"
              >
                <b-form-input
                  id="enterTitle"
                  v-model="paymentPhoneNumber"
                  placeholder="Enter your phone number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <ul>
              <li>
                Click Complete after receciving the MPESA confirmation
                message.
              </li>
            </ul>
            <b-button
              variant="outline-secondary"
              :to="{ name: 'invoice-view', params: { id: paymentInvoiceId } }"
            >
              Cancel
            </b-button>

            <b-button
              class="float-right"
              variant="primary"
              :disabled="isLoading"
              @click="processPayment"
            >
              {{ !isLoading ? "Send Prompt" : "Processing..." }}
              <b-spinner
                v-if="isLoading"
                small
              />
            </b-button>
          </validation-observer>
        </b-card>
        <b-card>
          <b-button
            class="float-right"
            variant="primary"
            :disabled="isLoading"
            @click="checkPaymentStatus(paymentInvoiceId)"
          >Complete</b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BFormInput,
  BFormGroup,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useInvoiceList from './useInvoiceList'
import invoiceStoreModule from './invoiceStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BFormInput,
    BFormGroup,
    BSpinner,

    ValidationProvider,
    ValidationObserver,
    required,
  },
  setup() {
    const toast = useToast()
    const invoiceData = ref(null)
    const paymentDetails = ref({})
    const totalAmount = ref(0)

    const INVOICE_APP_STORE_MODULE_NAME = 'invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    store
      .dispatch('invoice/fetchInvoice', {
        id: router.currentRoute.params.id,
      })
      .then(response => {
        invoiceData.value = response.data.data
        // paymentDetails.value = response.data.paymentDetails;
        paymentPhoneNumber.value = JSON.parse(
          localStorage.getItem('user'),
        ).phone_number
        paymentInvoiceId.value = invoiceData.value.invoice_id
        invoiceData.value.licenses.forEach(license => {
          totalAmount.value += license.tariff.price
        })
      })
      .catch(error => {
        if (error.response.status === 404) {
          invoiceData.value = undefined
        }
      })

    const checkPaymentStatus = invoice => {
      isLoading.value = true
      store
        .dispatch('invoice/checkPayment', { invoice_id: invoice })
        .then(response => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
          if (response.data.type === 'PSV') {
            router.replace({ name: 'licenses-psv' })
          } else if (response.data.type === 'Broadcaster') {
            router.replace({ name: 'licenses-broadcasting' })
          } else {
            router.replace({ name: 'licenses' })
          }
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    const {
      isLoading,

      refEnterPhoneNumberForm,
      paymentInvoiceId,
      paymentPhoneNumber,
      processPayment,

      checkPaymentInvoiceId,
      checkPayment,

      getAmountPaid,
      getInvoiceBalance,
      getDiscountedAmount,
    } = useInvoiceList()

    return {
      isLoading,

      invoiceData,
      paymentDetails,
      totalAmount,

      refEnterPhoneNumberForm,
      paymentInvoiceId,
      paymentPhoneNumber,
      processPayment,

      checkPaymentInvoiceId,
      checkPayment,

      checkPaymentStatus,

      getAmountPaid,
      getInvoiceBalance,
      getDiscountedAmount,
    }
  },
}
</script>

<style scoped>
  .settle-invoice-card {
    width: 50%
  }
  .instructions-card {
    width: 75%
  }
  @media only screen and (max-width: 992px) {
    .settle-invoice-card {
      width: 100%;
    }
    .instructions-card {
      width: 100%;
    }
  }
  </style>
